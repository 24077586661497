

























































































































































































































































import Vue from 'vue'
import {
  BCard, BRow, BCol, BFormInput, BTable, BPagination, BLink, BButton,
  BDropdown, BDropdownItem, BAvatar, BBadge, BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import TableSpinner from '@/views/components/table-spinner/TableSpinner.vue'
import pushStateFiltersParams from '@/helpers/pushStateFiltersParams'
import { appAdmins } from '@/store/modules/app-admins'

import { permissionSubjects } from '@/libs/acl/constants'

let debounceTimeout: ReturnType<typeof setTimeout> = setTimeout(() => '', 1000)

export default Vue.extend({
  name: 'AdminsList',
  components: {
    BCard,
    BRow,
    BCol,
    BAvatar,
    BFormInput,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    BBadge,
    BLink,
    BButton,
    BSpinner,

    vSelect,

    TableSpinner,
  },
  data() {
    return {
      currentRouteName: this.$router.currentRoute.name,
      permissionSubjects,
    }
  },
  computed: {
    tableColumns() {
      return appAdmins.getters.getTableColumns
    },
    perPage() {
      return appAdmins.getters.getPerPage
    },
    totalItems() {
      return appAdmins.getters.getTotalItems
    },
    currentPage: {
      get() {
        return appAdmins.getters.getCurrentPage
      },
      set(val: number) {
        appAdmins.mutations.SET_CURRENT_PAGE(val)
      },
    },
    perPageOptions() {
      return appAdmins.getters.getPerPageOptions
    },
    searchQuery: {
      get() {
        return appAdmins.getters.getSearchQuery
      },
      set(val: string) {
        appAdmins.mutations.SET_SEARCH_QUERY(val)
      },
    },
    filterDataStatus: {
      get() {
        return appAdmins.getters.getFilterDataStatus
      },
      set(val: string) {
        appAdmins.mutations.SET_FILTER_DATA_STATUS(val)
      },
    },
    sortBy() {
      return appAdmins.getters.getSortBy
    },
    isSortDirDesc() {
      return appAdmins.getters.getIsSortDirDesc
    },
    filterStatusOptions() {
      return appAdmins.getters.getFilterStatusOptions
    },
    isLoading() {
      return appAdmins.getters.getIsLoading
    },
    dataMeta() {
      return appAdmins.getters.getDataMeta
    },
  },
  watch: {
    $route() {
      if (!this.$route.query.currentPage) {
        this.setFilterParams()
      }

      this.updateTable()
    },
    currentPage(val, prevVal) {
      if (val !== prevVal) {
        pushStateFiltersParams(this, this.currentRouteName, 'currentPage', val)
      }
    },
    perPage(val, prevVal) {
      if (val !== prevVal) {
        pushStateFiltersParams(this, this.currentRouteName, 'perPage', val)
      }
    },
    searchQuery(val, prevVal) {
      if (val !== prevVal) {
        clearTimeout(debounceTimeout)
        debounceTimeout = setTimeout(() => {
          pushStateFiltersParams(this, this.currentRouteName, 'searchQuery', val)
        }, 250)
      }
    },
    filterDataStatus(val, prevVal) {
      if (val !== prevVal) {
        pushStateFiltersParams(this, this.currentRouteName, 'filterDataStatus', val)
      }
    },
  },
  created() {
    this.setFilterParams()
    // Update filters
    window.onpopstate = () => {
      this.setFilterParams()
    }
  },
  methods: {
    async exportUsersCSV() {
      await appAdmins.actions.exportUsersCSV()
    },
    updatePerPage(val: number) {
      appAdmins.mutations.SET_PER_PAGE(val)
    },
    clearFilters() {
      appAdmins.mutations.CLEAR_FILTERS()
    },
    setFilterParams() {
      appAdmins.mutations.SET_FILTER_PARAMS()
    },
    fetchAdminsList() {
      return appAdmins.actions.fetchAdminsList()
    },
    updateTable() {
      (this.$refs.refAdminsTable as BTable).refresh()
    },
    resolveStatusVariant(status: boolean) {
      if (status) return 'success'
      if (!status) return 'danger'
      return 'primary'
    },
  },
})
